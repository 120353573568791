export const ORG_TABS_KEYS = {
  DASHBOARD: "DASHBOARD",
  PROJECTS: "PROJECTS",
  TEAM: "TEAM",
  GUESTS: "GUESTS",
  ACTIVITY: "ACTIVITY",
  SETTINGS: "SETTINGS",
};

export const PROJECT_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "modified_date",
  SORT_OPTION_NAME: "name",
  SORT_OPTION_BRAND: "brand",
};

export const PROJECT_DESC_SORT_OPTIONS = {
  [Object.keys(PROJECT_SORT_OPTION_FIELD)[0]]: true,
  [Object.keys(PROJECT_SORT_OPTION_FIELD)[1]]: true,
  [Object.keys(PROJECT_SORT_OPTION_FIELD)[2]]: false,
};

export const ORGANISATION_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "modifiedDate",
  SORT_OPTION_NAME: "name",
};

export const TEAM_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "creation_date",
  SORT_OPTION_NAME: "name",
  SORT_OPTION_ROLE: "permissionsKey",
};

export const TEAM_SORT_OPTION_KEYS = Object.keys(TEAM_SORT_OPTION_FIELD);

export const GUEST_SORT_OPTION_KEYS = Object.keys(TEAM_SORT_OPTION_FIELD).slice(
  0,
  2
);

export const DEVICE_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "updatedAt",
  SORT_OPTION_IMEI: "deviceId",
  SORT_OPTION_STOREID: "storeId",
  SORT_OPTION_ONLINE: "updatedAt",
  SORT_OPTION_COMPLIANCE: "state",
};

export const DEVICE_DESC_SORT_OPTIONS = {
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[0]]: true,
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[1]]: false,
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[2]]: false,
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[3]]: true,
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[4]]: true,
};

export const DEVICE_SORT_OPTION_KEYS = Object.keys(DEVICE_SORT_OPTION_FIELD);

export const navigationBarHeight = "80px";
export const navigationBarWidth = "80px";
